@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans/OpenSans-Light.ttf");
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans/OpenSans-Regular.ttf");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "OpenSans";
    src: url("./assets/fonts/OpenSans/OpenSans-Bold.ttf");
    font-weight: 700;
    font-display: swap;
  }
}

@layer components {
  .container {
    @apply xl:max-w-[1110px];
  }
}
