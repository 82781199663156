.ckeditor p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ckeditor blockquote {
  @apply prose prose-slate max-w-full border-l-8 px-5 py-1 bg-[#F6F9FF];
}

.ckeditor ol {
  @apply list-decimal ml-10;
}

.ckeditor ul {
  @apply list-disc ml-10;
}

.ckeditor a {
  @apply text-blue-500;
}

.ckeditor h1,
.ckeditor h2,
.ckeditor h3 {
  font-weight: normal;
  line-height: 1.2;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.ckeditor h1 {
  display: block;
  font-size: 2em;
}

.ckeditor h2 {
  display: block;
  font-size: 1.5em;
}

.ckeditor h3 {
  display: block;
  font-size: 1.17em;
}

.ckeditor img {
  @apply min-w-full md:min-w-0 rounded-[12px] object-cover inline-block;
}

.ckeditor table {
  width: 100% !important;
  border-collapse: collapse;
}
.ckeditor td,
.ckeditor th {
  border: 1px solid #ddd;
  padding: 8px;
}
.ckeditor th {
  @apply font-bold bg-gray-300;
}
