@tailwind components;

@layer components {
  .privacy-policy {
    @apply container mx-auto my-10 space-y-5;
  }

  .privacy-policy h1 {
    @apply text-4xl font-bold;
  }

  .privacy-policy h2 {
    @apply text-2xl font-bold;
  }

  .privacy-policy ul {
    @apply list-disc list-inside ml-10;
  }
}
